import React from "react"
import { Link, graphql } from "gatsby"
import Card from "../components/card"
import SearchBar from "../components/search"
import HomeNav from "../components/homeNav"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title || `Title`

  return (
    <Layout location={location} title={siteTitle} showNav>
      <Seo title="Home" />
      <SearchBar />
      <Link
      to={`/search`}
      state={{ searchQuery: 'student' }}
    >
      View Photo
    </Link>
      <Card />
      <HomeNav />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"
import { Formik, Form, Field } from "formik"

const SearchBar = () => {
  const data = useStaticQuery(graphql`
    {
      localSearchPages {
        index
        store
      }
    }
  `)

  const index = data.localSearchPages.index
  const store = data.localSearchPages.store
  const options = {
      tokenize: 'forward',
      suggestions: true
  }
  const [query, setQuery] = useState(null)
  const results = useFlexSearch(query, index, store, options)
//   {console.log(index)}
  
  return (
      <div>
      <Formik
        initialValues={{ query: "" }}
        onSubmit={(values, { setSubmitting }) => {
            setQuery(values.query)
            setSubmitting(false)
        }}
        >
        <Form>
          <Field name="query" />
        </Form>
      </Formik>
      <h1>Results</h1>
      <ul>
        {results.map(result => (
          <li key={result.id}>
             <Link to={result.slug} style={{ color: "#39C9A7" }}>
              {result.title}
           </Link>
           <div dangerouslySetInnerHTML={{__html: result.excerpt}}/>
          
              </li>
        ))}
      </ul>
    </div>
  )
}

export default SearchBar

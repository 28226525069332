import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import teacherImg from "../images/index-flip.png"
import studentImg from "../images/interactive-video.png"
import schoolLeaderImg from "../images/index-teachers.png"
import parentGuardianImg from "../images/interactive-videos.png"

const HomeNav = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          menuLinks {
            link
            name
          }
        }
      }
    }
  `)
  const menuItems = data.site.siteMetadata.menuLinks
  return (
    <div className="row mt-4 row-roles">
      <div className="col-12">
        <h1 className="heading">Are you a:</h1>
      </div>
      <div className="col-12 mt-2">
        <div className="row">
          <div className="col-lg-3 role-column">
            <div className="role-image" style={{ backgroundColor: "#39C9A7" }}>
              <Link to="/teacher/">
                <div
                  className="role-image_img role-image_img--small position-relative"
                  style={{ backgroundImage: `url(${teacherImg})` }}
                ></div>
              </Link>
            </div>
            <div className="role-name">
              <Link to="/teacher/" style={{ color: "#39C9A7" }}>
                Teacher
              </Link>
            </div>
          </div>
          <div className="col-lg-3 role-column">
            <div className="role-image" style={{ backgroundColor: "#6E4BEC" }}>
              <Link to="/student/">
                <div
                  className="role-image_img role-image_img--small position-relative"
                  style={{ backgroundImage: `url(${studentImg})` }}
                ></div>
              </Link>
            </div>
            <div className="role-name">
              <Link to="/student/" style={{ color: "#6E4BEC" }}>
                Student
              </Link>
            </div>
          </div>
          <div className="col-lg-3 role-column">
            <div className="role-image" style={{ backgroundColor: "#1981FF" }}>
              <Link to="/school-leader/">
                <div
                  className="role-image_img position-relative"
                  style={{ backgroundImage: `url(${schoolLeaderImg})` }}
                ></div>
              </Link>
            </div>
            <div className="role-name">
              <Link to="/school-leader/" style={{ color: "#1981FF" }}>
                School Leader
              </Link>
            </div>
          </div>
          <div className="col-lg-3 role-column">
            <div className="role-image" style={{ backgroundColor: "#FF193D" }}>
              <Link to="/parents-guardians/">
                <div
                  className="role-image_img position-relative"
                  style={{ backgroundImage: `url(${parentGuardianImg})` }}
                ></div>
              </Link>
            </div>
            <div className="role-name">
              <Link to="/parents-guardians/" style={{ color: "#FF193D" }}>
                Parents/Guardian
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomeNav

import React from "react"

const Card = ({ isRootPath }) => {
  return (
    <div className="card mb-4 card--title">
      <div className="card-body">
        <div className="d-flex align-items-center">
          <h1 className="heading">
            Welcome to the mySecondTeacher Training Guide.
          </h1>
        </div>
        <div className="mt-2">
          <p>
            <strong>mySecondTeacher</strong> is an award-winning academic
            delivery platform with comprehensive interactive content for the
            Cambridge schools’ syllabus, such as Primary Checkpoint, IGCSEs, and
            the A Levels.
          </p>
          <p>
            In this training guide you shall learn how to use the key features
            of <strong>mySecondTeacher</strong>.
          </p>
        </div>
      </div>
    </div>
  )
}
export default Card
